import { bool, func, shape, string } from 'prop-types';
import React from 'react';
import { colours, textSizes } from '../../../assets/theme';
// import Checkbox from '../../atoms/Checkbox';
import Select from '../../atoms/Select';
import { P, Label } from '../../atoms/Text';
import { Row } from '../../Layout/Flex';
import { ErrorMessage } from '../NewsletterForm/styles';
import { Form, InputsWrapper, SubmitButton, SpacedCheckbox as Checkbox } from './styles';

const UpdateSubscriptionForm = ({
  onSubmit,
  formState,
  languageOptions,
  onChangeLang,
  onChange,
  hasError,
  formPostStatusMessage,
}) => {
  // const translations = useTranslation();

  return (
    <Form className="newsletter-form" onSubmit={onSubmit}>
      <P>Choose below what kind of emails you would like to receive:</P>
      <InputsWrapper>
        <Checkbox
          checked={formState.newsEmails}
          onChange={() =>
            onChange({
              newsEmails: !formState.newsEmails,
              progEmails: formState.progEmails,
              langIndex: formState.langIndex,
            })
          }
          label="Subscribe for occational update emails"
          link={{ to: '/', label: 'preview...' }}
        />
        <Checkbox
          checked={formState.progEmails}
          onChange={() =>
            onChange({
              progEmails: !formState.progEmails,
              newsEmails: formState.newsEmails,
              langIndex: formState.langIndex,
            })
          }
          label="Get the Loose Antenna program in your inbox"
          link={{ to: '/', label: 'preview...' }}
        />
        {hasError.label && (
          <ErrorMessage size={textSizes.sm} colour={colours.red}>
            {hasError.label}
          </ErrorMessage>
        )}
        <Row align="center">
          <Label>Language:</Label>
          <Select options={languageOptions} onChange={onChangeLang} selectedIndex={formState.langIndex} />
        </Row>
      </InputsWrapper>
      <SubmitButton
        onSubmit={onSubmit}
        onClick={onSubmit}
        data-hoverable={!hasError.status}
        disabled={hasError.status}
      >
        <Label size={textSizes.lg}>SUBMIT →</Label>
      </SubmitButton>
      {formPostStatusMessage && (
        <ErrorMessage align="center" size={textSizes.sm} colour={colours.green}>
          {formPostStatusMessage}
        </ErrorMessage>
      )}
    </Form>
  );
};

UpdateSubscriptionForm.propTypes = {
  onSubmit: func,
  onChange: func,
  formState: shape({
    newsEmails: bool,
    progEmails: bool,
  }),
  hasError: shape({
    label: string,
    status: bool,
  }),
};

export default UpdateSubscriptionForm;
