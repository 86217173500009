import styled from 'styled-components';
import Checkbox from '../../atoms/Checkbox';

export const Form = styled.form`
  border: 1px solid ${({ theme }) => theme.foreground};
  padding: 24px;
`;

export const SubmitButton = styled.button`
  background: transparent;
  border: 1px solid ${({ theme }) => theme.foreground};
  padding: 12px 16px;
  text-align: center;
  width: 100%;
  cursor: none;

  & label {
    cursor: none;
  }

  &:hover, &:focus {
    background: ${({ theme }) => theme.foreground};
    label {
      color: ${({ theme }) => theme.background};
    }
  }
  
  &:disabled {
    opacity: 0.5;
    background: transparent;
    label {
      color: ${({ theme }) => theme.foreground};
    }
  }
`;

export const InputsWrapper = styled.div`
  padding: 32px 0;
`;

export const SpacedCheckbox = styled(Checkbox)`
  margin-bottom: 16px;
`;
