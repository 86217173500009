import { arrayOf, number, shape, string } from 'prop-types';
import React, { useRef } from 'react';
import { useToggle } from 'react-use';
import { withTheme } from 'styled-components';
import { useOnClickOutside } from '../../../hooks/domHooks';
import { Label } from '../Text';
import { Box, Item, Container, OptionsWrapper, ChevronIcon } from './styles';

const Select = withTheme(({ options, selectedIndex, onChange, theme }) => {
  const [isOpen, setIsOpen] = useToggle(false);
  const close = () => {
    setIsOpen(false);
  };
  const open = () => {
    setIsOpen(true);
  };
  const optionsRef = useRef([]);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const ref = useRef();
  useOnClickOutside(ref, () => close());

  const handleItemClick = index => {
    if (typeof onChange === 'function') {
      close();
      onChange(index);
    }
  };

  const handleKeyPress = e => {
    e.preventDefault();
    if (e.keyCode === 32) {
      open();
    }
    if (e.keyCode === 40) {
      open();
      // optionsRef.current[1].focus();
    }
  };

  return (
    <Container ref={ref}>
      {options?.length ? (
        <>
          <Box onClick={toggle} tabIndex={0} onKeyDown={handleKeyPress} data-hoverable>
            <Label>{options[selectedIndex].label}</Label>
            <ChevronIcon colour={theme.foreground} size={20} />
          </Box>
          {isOpen && options?.length ? (
            <OptionsWrapper ref={optionsRef}>
              {options.map(
                (option, optionIndex) =>
                  option.value !== options[selectedIndex].value && (
                    <Item
                      tabIndex={0}
                      data-hoverable
                      onClick={() => handleItemClick(optionIndex)}
                      key={option.value}
                      ref={optionsRef[optionIndex]}
                    >
                      {option.label}
                    </Item>
                  ),
              )}
            </OptionsWrapper>
          ) : null}
        </>
      ) : (
        <Box />
      )}
    </Container>
  );
});

Select.propTypes = {
  options: arrayOf(
    shape({
      label: string,
    }),
  ),
  selectedIndex: number,
};

export default Select;
