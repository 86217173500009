import React from 'react';
import styled, { css } from 'styled-components';
import ChevronSvg from '../../icons/Chevron';
import { Label } from '../Text';

export const Container = styled.div`
  position: relative;
  max-width: min-content;
  margin-left: 8px;
`;

export const OptionsWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  div {
    margin-top: -1px;
  }
`;

export const Box = styled.div`
  padding: 4px 16px;
  border: 1px solid ${({ theme }) => theme.foreground};
  background: ${({ theme }) => theme.background};
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  label {
    position: relative;
    top: 1px;
  }

  ${({ selectable }) => selectable && css`
    &:hover {
      background: ${({ theme }) => theme.foreground};
      label {
        color: ${({ theme }) => theme.background};
      }
    }
  `}
`;

export const Item = ({ label, children, ...rest }) => (
  <Box selectable {...rest}>
    <Label>{label ?? children}</Label>
  </Box>
);

export const ChevronIcon = styled(ChevronSvg)`
  margin-left: 16px
`;
