import React from 'react';
import { number, string } from 'prop-types';
import { colours } from '../../assets/theme';

const ChevronSvg = ({ size = '24', colour = colours.black, ...rest }) => (
  <svg viewBox="0 0 24 24" height={size} width={size} {...rest}>
    <path d="M3 9.5l9 6.5 9-7" stroke={colour} strokeWidth="1" fill="none" />
  </svg>
);

ChevronSvg.propTypes = {
  size: number,
  colour: string,
};

export default ChevronSvg;
