import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import { colours, textSizes } from '../assets/theme';
import Link from '../components/atoms/Link';
import { TextP, Contents, Header } from './confirm-email-sub';
import Page from '../components/organisms/Page';
import { useLangStore } from '../store';
import { useTranslation } from '../hooks/TranslationHooks';
import UpdateSubscriptionForm from '../components/molecules/UpdateSubscriptionForm';
import { SubmitButton } from '../components/molecules/UpdateSubscriptionForm/styles';
import { Label } from '../components/atoms/Text';

const UpdateSubscriptionPage = () => {
  // const lang = useLangStore(store => store.currentLang);
  const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'fr', label: 'Français' },
  ];
  const urlParams = typeof window === 'undefined' ? null : new URLSearchParams(window.location.search);
  const id = urlParams?.get('id');
  const reset = urlParams?.get('reset');
  const userPreferences = useRef({});
  const [pageState, setPageState] = useState('loading');

  const [formState, updateFormState] = useState({});

  const [formPostStatusMessage, updateFormPostStatusMessage] = useState('');
  const [formHasError, updateFormHasError] = useState({
    label: '',
    status: false,
  });

  useEffect(() => {
    if (!id || !reset) {
      setPageState('invalid');
    } else {
      getSubscriptionPreferences();
    }
  }, []);

  useEffect(() => {
    updateFormPostStatusMessage('');
    if (
      (formState.newsEmails === userPreferences.current.newsEmails &&
        formState.progEmails === userPreferences.current.progEmails &&
        formState.langIndex === userPreferences.current.langIndex) ||
      (!formState.progEmails && !formState.newsEmails)
    ) {
      updateFormHasError({
        status: true,
        label: '',
      });
    } else {
      updateFormHasError({
        status: false,
        label: '',
      });
    }
  }, [formState]);

  const handleChangeLang = langIndex => {
    updateFormState({ ...formState, langIndex });
  };

  const getSubscriptionPreferences = async () => {
    try {
      const { data } = await axios.get(
        `https://cms.looseantenna.fm/subscribers/preferences?id=${id}&reset=${reset}`,
      );
      if (data) {
        const result = {
          newsEmails: data.newsletter,
          progEmails: data.program_emails,
          langIndex: languageOptions.findIndex(langOption => langOption.value === data.lang) ?? 1,
        };
        userPreferences.current = result;
        updateFormState(result);
        setPageState('ready');
      }
    } catch (err) {
      console.log({ err });
      setPageState('invalid');
    }
  };

  const updateSubscription = async event => {
    event.preventDefault();
    try {
      const { status, data } = await axios.put(
        `https://cms.looseantenna.fm/subscribers/preferences?id=${id}&reset=${reset}`,
        {
          lang: languageOptions[formState.langIndex].value,
          newsletter: formState.newsEmails,
          programEmails: formState.progEmails,
        },
      );
      console.log({ status });
      if (status === 200) {
        updateFormHasError({ status: true, label: '' });
        updateFormPostStatusMessage('Settings updated!');

        const result = {
          newsEmails: data.newsletter,
          progEmails: data.program_emails,
          langIndex: languageOptions.findIndex(langOption => langOption.value === data.lang) ?? 1,
        };
        userPreferences.current = result;
        // updateFormState(result);
      }
    } catch (err) {
      console.log({ err });
      setPageState('invalid');
    }
  };

  return (
    <Page title="Update subscription" noindex>
      <Contents>
        {pageState === 'ready' ? (
          <div>
            <UpdateSubscriptionForm
              onSubmit={updateSubscription}
              formState={formState}
              onChange={updateFormState}
              hasError={formHasError}
              formPostStatusMessage={formPostStatusMessage}
              languageOptions={languageOptions}
              onChangeLang={handleChangeLang}
            />
            <TextP align="center" size={textSizes.lg} selectable={false}>
              {' '}
              /{' '}
            </TextP>
            <CenteredLink to={`/unsubscribe?id=${id}&reset=${reset}`} hoverable>
              <SubmitButton>
                <Label letterCase="upper">unsubscribe</Label>
              </SubmitButton>
            </CenteredLink>
          </div>
        ) : (
          <div>
            <PageInner pageState={pageState} />
            <Link to="/">
              <TextP align="center" colour={colours.blue}>
                → Go home
              </TextP>
            </Link>
          </div>
        )}
      </Contents>
    </Page>
  );
};

const PageInner = ({ pageState }) => {
  const currentLang = useLangStore(store => store.currentLang);
  const successHeaderMessage = useTranslation({ key: 'updateSubscriptionSuccess' })[currentLang];
  const successMessage = useTranslation({ key: 'unsubscribeSuccessfulMessage' })[currentLang];
  const invalidRequestHeaderMessage = useTranslation({ key: 'invalidRequest' })[currentLang];
  const invalidRequestMessage = useTranslation({ key: 'invalidRequestMessage' })[currentLang];

  if (pageState === 'invalid') {
    return (
      <>
        <Header align="center">Error: {invalidRequestHeaderMessage}</Header>
        <TextP align="center">{invalidRequestMessage}</TextP>
      </>
    );
  }
  if (pageState === 'success') {
    return (
      <>
        <Header align="center">{successHeaderMessage}</Header>
        <TextP align="center">{successMessage}</TextP>
      </>
    );
  }
  return null;
};

const CenteredLink = styled(Link)`
  a {
    display: block;
    text-align: center;

    button {
      max-width: max-content;
    }
  }
`;

export default UpdateSubscriptionPage;
